import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useTipList() {
  const vm = getCurrentInstance().proxy

  const tipsListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', width: '5%' },
    { text: 'SORT', value: 'sort', width: '10%' },
    { text: 'TITLE', value: 'title', width: '60%' },
    { text: 'COLOR', value: 'color', width: '10%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const tips = computed({
    get: () => vm.$store.getters['tips/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['tips/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['tips/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['tips/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['tips/options'],
    set: val => vm.$store.dispatch('tips/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['tips/search'],
    set: val => vm.$store.dispatch('tips/setSearch', val),
  })
  const totalTipListTable = computed({
    get: () => vm.$store.getters['tips/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('tips/fetchItems')
  const setOptions = val => vm.$store.dispatch('tips/setOptions', val)
  const setFilters = val => vm.$store.dispatch('tips/setFilters', val)
  const handleReorder = val => vm.$store.dispatch('tips/order', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    tipsListTable,
    tableColumns,

    totalTipListTable,
    loading,

    fetchItems,

    meta,
    tips,
    options,
    filters,
    search,

    setOptions,
    setFilters,
    handleReorder,

    init,
    destroy,
  }
}
