<template>
  <div id="user-list">
    <v-row class="mb-5"></v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Search &amp; Filter
          </v-col>

          <v-col
            align="end"
            class="pb-0"
          >
            <v-tooltip
              v-if="$can('create', 'Tip')"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  x-small
                  elevation="4"
                  color="primary"
                  :to="{ name: 'tip-create' }"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Add New Tip</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- table -->
      <vuex-table
        v-sortable-data-table
        :headers="tableColumns"
        :items="tips"
        :meta.sync="meta"
        :search.sync="search"
        :options="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        item-key="id"
        :item-class="renderRowClass"
        @sorted="handleOrder"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.title`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.image ? '' : 'primary'"
              :class="item.image ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.thumbUrl"
                :src="item.thumbUrl"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.title) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'tip-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.title | textEllipsis(120) }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'tip-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Tips</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('update', 'Tip')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'tip-edit', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit Tip</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('destroy', 'Tip')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="error"
                v-bind="attrs"
                :disabled="deleting"
                :loading="isDialogOpen"
                @click="selectTipToDelete(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete Tips</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>

    <tip-delete-dialog
      v-if="$can('destroy', 'Tip')"
      :data="selectedTips"
      :deleting="deleting"
      :is-dialog-open.sync="isDialogOpen"
    ></tip-delete-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiPencil, mdiDeleteOutline, mdiExportVariant } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { ref, computed, getCurrentInstance, onMounted, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useTipList from './useTipList'
import TipDeleteDialog from './TipDeleteDialog.vue'

export default {
  components: {
    TipDeleteDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const isDialogOpen = ref(false)

    const deleting = computed(() => vm.$store.getters['tips/deleting'])
    const selectedTips = ref(null)
    const selectTipToDelete = tips => {
      selectedTips.value = tips
      isDialogOpen.value = !!tips
    }

    const {
      tableColumns,
      totalTipsListTable,
      loading,

      meta,
      filters,
      options,
      search,
      roleFilter,

      setOptions,
      setFilters,

      tips,
      tipsListTable,
      tipsTotalLocal,

      handleReorder,

      init,
      destroy,
    } = useTipList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const handleOrder = e => {
      // console.error(e, e.oldIndex, e.newIndex, tips.value)
      const movedItem = Array.from(tips.value).splice(e.oldIndex, 1)[0]
      const replacedItem = Array.from(tips.value).splice(e.newIndex, 1)[0]

      // console.error({ movedItem, replacedItem })

      // tips.splice(e.newIndex, 0, movedItem)

      handleReorder({
        id: movedItem.id,
        sort: replacedItem.sort,
      })
    }

    const renderRowClass = item => `tip-${item.id}`

    return {
      tipsListTable,
      tableColumns,
      totalTipsListTable,

      loading,
      tipsTotalLocal,
      avatarText,

      setOptions,
      setFilters,

      tips,
      meta,
      filters,
      options,
      search,
      roleFilter,

      // icons
      icons: {
        mdiPlus,
        mdiPencil,
        mdiEyeOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiExportVariant,
      },

      isDialogOpen,

      deleting,
      selectedTips,
      selectTipToDelete,

      handleOrder,
      renderRowClass,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
